import React from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

import history from "../history";
import {Path} from "../Const";

class Main extends React.Component {
  constructor() {
    super();
    this.state = {
      selected: '/'
    };
  }

  render() {
    return (
      <Navbar variant="dark" bg="dark-overlay" className="justify-content-between" fixed="top" collapseOnSelect expand="lg">
        <Navbar.Brand href={Path.HOME} style={{color: 'var(--color-main)'}}>Alexander and Daniel Gurfinkel</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
          <Nav 
            activeKey={history.location.pathname}
            onSelect={selectedKey => {
              this.setState({selected: selectedKey});
              history.push(selectedKey);
            }}
            >
            <Nav.Link eventKey={Path.HOME}>Home</Nav.Link>
            <Nav.Link eventKey={Path.BIOGRAPHY}>Biography</Nav.Link>
            <Nav.Link eventKey={Path.TRIO} style={{whiteSpace: 'nowrap'}}>Diamond Trio</Nav.Link>
            <Nav.Link eventKey={Path.REPERTOIRE}>Repertoire</Nav.Link>
            <Nav.Link eventKey={Path.CONCERTS}>Concerts</Nav.Link>
            <Nav.Link eventKey={Path.REVIEWS}>Reviews</Nav.Link>
            <Nav.Link eventKey={Path.RECORDINGS}>Recordings</Nav.Link>
            <Nav.Link eventKey={Path.GALLERY}>Gallery</Nav.Link>
            <Nav.Link eventKey={Path.CONTACT}>Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Main;