import {MonthNames, Months, ThreeLetterMonths} from '../utils/Const';

// country codes from here: https://www.nationsonline.org/oneworld/country_code_list.htm
export const Concerts = [
  // location: optional, long: optional
  // { flag: "", location: "", short: '', long: '', date: new Date()},
  { flag: "hu", location: "Budapest, Hungary", short: 'with BZD Symphony Orchestra',
    long: 'E. Levitas "Remembering Benny Goodman" (Concert for two clarinets and symphony orchestra); Conductor: Vakhtang Kakhidze',
    date: new Date(2023, Months.MAR, 13)
  },
  { flag: "de", location: "Bremerhaven, Germany", short: 'with Bremerhaven Symphony Orchestra',
    date: new Date(2023, Months.JAN, 1),
    long: 'Conductor Mark Niemann'
  },
  { flag: "fr", location: "France", short: 'Festival 100% Classique',
    date: new Date(2022, Months.SEP, 24)
  },
  { flag: "il", location: "Israel", short: 'with ISO',
    long: 'E. Levitas "Remembering Benny Goodman" (Concert for two clarinets and symphony orchestra); Conductor: John Axelrod',
    range: {from: new Date(2022, Months.JUN, 2), to: new Date(2022, Months.JUN, 8)}
  },
  { flag: "at", location: "Graz, Austria", short: 'with Michael Gurfinkel',
    long: "Ivan Ëord Concerto for three clarinets",
    range: {from: new Date(2022, Months.MAY, 22), to: new Date(2022, Months.MAY, 23)}
  },
  { flag: "ru", location: "Omsk, Russia", short: 'with Omsk Symphony Orchestra',
    date: new Date(2022, Months.MAR, 31),
    long: 'Conductor Dmitry Vasilev'
  },
  { flag: "hu", location: "Budapest, Hungary", short: 'with Dohnányi Symphony Orchestra',
    long: 'E. Levitas "Remembering Benny Goodman" (Concert for two clarinets and symphony orchestra); Conductor: Vakhtang Kakhidze',
    date: new Date(2022, Months.FEB, 20)
  },
  {
    flag: "ua", location: "Dnipro, Ukraine",
    date: new Date(2021, Months.SEP, 11),
    long: 'Conductor Dmitry Logvin'
  },
  { flag: "de", location: "Nuremberg, Germany", short: 'with Nuremberg Symphony Orchestra',
    range: {from: new Date(2021, Months.JUL, 7), to: new Date(2021, Months.JUL, 12)},
    long: 'F. Mendelssohn; E.Levitas'
  },
  { flag: "il", location: "Be'er Sheva, Israel", short: "with Israeli Sinfonietta Be'er Sheva",
    long: 'Conductor: Nir Kabaretti', date: new Date(2021, Months.MAY, 1)
  },
  { flag: "il", location: "Haifa, Israel", short: 'with Haifa Big Band Jazz Orchestra',
    long: 'Bizet/Levitas "Carmen 21" (arr. for Big Band T.B.A.)', date: new Date(2021, Months.APR, 27)
  },
  {
    flag: "ua", location: "Dnipro, Ukraine", short: 'with Festival Symphony Orchestra',
    date: new Date(2021, Months.APR, 19),
    long: 'Conductor Dmitry Logvin'
  },
  { flag: "at", location: "Graz, Austria", short: "PSALM Festival",
    date: new Date(2021, Months.MAR, 31)
  },
  { flag: "il", location: "Eilat, Israel", short: 'Israel Music International Festival',
    long: 'with Raanana Symphonette Orchestra; Conductor: Omer Wellber; E.Levitas "Remember Benny Goodman"',
    date: new Date(2021, Months.JAN, 9)
  },
  { flag: "il", location: "Eilat, Israel", short: 'Israel Music International Festival',
    long: 'Diamond Trio: Duo Gurfinkel (clarinets), Silvia Patru (piano)',
    date: new Date(2021, Months.JAN, 8)
  },
  {
    flag: "de", location: "Donaueschingen, Germany", short: "New Year's Concert",
    long: 'Das Resort Der Öschberghof',
    date: new Date(2020, Months.DEC, 29)
  },
  { flag: "ee", location: "Tallinn, Estonia", short: 'with Estonian Sinfonietta',
    date: new Date(2020, Months.DEC, 27)
  },
  { flag: "ru", location: "Krasnoyarsk, Russia", short: 'with Krasnoyarsk Symphony Orchestra',
    date: new Date(2020, Months.DEC, 16)
  },
  { flag: "ru", location: "Norilsk, Russia", short: 'Diamond Trio: Trans-Siberian Art Festival',
    date: new Date(2020, Months.DEC, 13)
  },
  { flag: "hu", location: "Budapest, Hungary", short: 'with Dohnányi Symphony Orchestra',
    long: 'E. Levitas "Remembering Benny Goodman" (Jazz concert for two clarinets and symphony orchestra); Conductor: Vato Kakhidze',
    date: new Date(2020, Months.DEC, 9)
  },
  {
    flag: "de", location: "Berlin, Germany", short: "Recital \"Clarinetomania\"",
    date: new Date(2020, Months.NOV, 29)
  },
  {
    flag: "de", location: "Wiesbaden, Germany", short: "Recital \"Clarinetomania\"",
    date: new Date(2020, Months.NOV, 20)
  },
  // {
  //   flag: "ua", location: "Dnipro, Ukraine", short: '"Music without Borders" Festival',
  //   date: new Date(2020, Months.OCT, 20),
  //   long: 'Soloist: Alex Gurfinkel, Maxim Rysaev; Conductor Dmitry Logvin; Bruch: Concert for clarinet, viola and orchestra'
  // },
  // {
  //   flag: "ua", location: "Dnipro, Ukraine", short: '"Music without Borders" Festival',
  //   date: new Date(2020, Months.OCT, 20),
  //   long: 'Soloist: Daniel Gurfinkel; Conductor Dmitry Logvin; Mozart: Concert for clarinet and orchestra'
  // },
  // {
  //   flag: "ua", location: "Dnipro, Ukraine", short: '"Music without Borders" Festival',
  //   date: new Date(2020, Months.OCT, 19),
  //   long: 'Conductor Dmitry Logvin; E. Levitas - "Remembering Benny Goodman" for two clarinets and symphony orchestra'
  // },
  { flag: "at", location: "Graz, Austria", short: 'Mozart Concert with Graz Symphony Orchestra',
    long: "Soloist: Alex Gurfinkel",
    range: {from: new Date(2020, Months.OCT, 12), to: new Date(2020, Months.OCT, 13)}
  },
  { flag: "ru", location: "Saint Petersburg, Russia", short: "Recital with Elizabeta Blumina",
    date: new Date(2020, Months.SEP, 30)
  },
  // { flag: "", location: "South America Tour", short: "with GKO", long: "Conductor: Ruben Gazarian",
  //   range: { from: new Date(2020, Months.AUG), to: new Date(2020, Months.AUG), hideDays: true}
  // },
  // { flag: "de", location: "Germany", short: "with Nuremberg Symphony Orchestra",
  //   long: 'Mendelssohn - "Konzertstück 1 op. 113"; E. Levitas - "Remembering Benny Goodman Concerto" for two clarinets and symphony orchestra',
  //   range: {from: new Date(2020, Months.JUL, 8), to: new Date(2020, Months.JUL, 10)}
  // },
  // { flag: "nl", location: "Netherlands", short: 'Festival',
  //   date: new Date(2020, Months.JUN, 20)
  // },
  // { flag: "de", location: "Kurpfälzisches, Germany", short: "with Chamber Orchestra",
  //   date: new Date(2020, Months.JUN, 13)
  // },
  // { flag: "il", location: "Israel Tour", short: "with Amernet String Quartet (USA)",
  //   range: {from: new Date(2020, Months.MAY, 21), to: new Date(2020, Months.JUN, 6)}
  // },
  // { flag: "ru", location: "Omsk, Russia", short: "with Omsk Academic Symphony Orchestra",
  //   date: new Date(2020, Months.MAR, 22)
  // },
  { flag: "es", location: "Seville, Spain", short: "with Seville Symphony Orchestra",
    long: "Benjamin Yusupov - concert for two clarinets and symphony orchestra",
    range: {from: new Date(2020, Months.FEB, 20), to: new Date(2020, Months.FEB, 21)}
  },
  { flag: "de", location: "Dortmund, Germany", date: new Date(2020, Months.JAN, 18) },
  { flag: "de", location: "Ingolstadt, Germany", short: "New Year's Eve Gala Concert - with Georgian Chamber Orchestra",
    date: new Date(2020, Months.JAN, 1)
  },
  { flag: "ee", location: "Estonia", short: 'with Estonian Sinfonietta',
    date: new Date(2019, Months.DEC, 8)
  },
  { flag: "it", location: "Salerno, Italy", short: "Diamond Trio Recital with Alex Gurfinkel, Daniel Gurfinkel and Silvia Patru",
    long: "Teatro Verdi Salerno", date: new Date(2019, Months.DEC, 4)
  },
  { flag: "mx", location: "Monterrey, Mexico", short: "with Monterrey Chamber Orchestra",
    date: new Date(2019, Months.NOV, 6)
  },
  { flag: "it", location: "Sanremo, Italy", short: "with Sanremo Symphony Orchestra",
    date: new Date(2019, Months.OCT, 17)
  },
  { flag: "fr", location: "France", short: "Festival",
    date: new Date(2019, Months.SEP, 28)
  },
  { flag: "de", location: "Meister von Morgen, Germany", short: "Diamond Trio Recital with Alex Gurfinkel, Daniel Gurfinkel and Silvia Patru",
    date: new Date(2019, Months.SEP, 22)
  },
  { flag: "ge", location: "Tbilisi, Georgia", short: "'Autumn Tbilisi' Music Festival - with Tbilisi Symphony Orchestra",
    long: "Conductor: Vakhtang Kakhidze", date: new Date(2019, Months.SEP, 18)
  },
  { flag: "de", location: "Mainz, Germany", date: new Date(2019, Months.SEP, 1) },
  { flag: "fr", location: "Cannes, France", short: "Cannes Festival with Cannes Symphony Orchestra",
    date: new Date(2019, Months.JUL, 22)
  },
  { flag: "de", location: "Ingolstadt, Germany", short: "with Georgian Chamber Orchestra",
    date: new Date(2019, Months.JUN, 6)
  },

  { flag: "de", location: "Braunschweig, Germany", short: "with Elisaveta Blumina", date: new Date(2019, Months.DEC, 3) },
  { flag: "de", location: "Marburg, Germany", short: "with Elisaveta Blumina", date: new Date(2019, Months.DEC, 1) },
  { flag: "de", location: "Bremen, Germany", short: "with Elisaveta Blumina", date: new Date(2019, Months.NOV, 24) },
  { flag: "de", location: "Hamburg, Germany", short: "with Elisaveta Blumina", date: new Date(2019, Months.NOV, 17) },
  { flag: "de", location: "Stuttgart, Germany", short: "Recital", date: new Date(2019, Months.NOV, 10) },
  { flag: "de", location: "Rostock, Germany", short: "with Elisaveta Blumina", date: new Date(2019, Months.OCT, 23) },
  { flag: "de", location: "Hanover, Germany", short: "with Elisaveta Blumina", date: new Date(2019, Months.MAY, 9) },
  { flag: "de", location: "Herford, Germany", short: "with Elisaveta Blumina", date: new Date(2019, Months.MAY, 19) },
  { flag: "de", location: "Hof, Germany", short: "with Elisaveta Blumina", date: new Date(2019, Months.DEC, 15) },


  { flag: "ru", location: "Krasnoyarsk, Russia", short: "with Siberian State Symphony Orchestra",
    long: "Conductor: Vladimir Lande", date: new Date(2019, Months.MAY, 1)
  },
  { flag: "ru", location: "Akademgorodok, Russia", short: "with Novosibirsk Philharmonic Symphony",
    date: new Date(2019, Months.APR, 27)
  },
  { flag: "ru", location: "Novosibirsk, Russia", short: "with Novosibirsk Philharmonic Symphony",
    date: new Date(2019, Months.APR, 26)
  },
  { flag: "ru", location: "Akademgorodok, Russia", short: "Chamber Concert with Filharmoniya Quartet",
    date: new Date(2019, Months.APR, 24)
  },
  { flag: "ru", location: "Novosibirsk, Russia", short: "Chamber Concert with Filharmoniya Quartet",
    long: 'at The Arnold Katz State Concert Hall', date: new Date(2019, Months.APR, 23)
  },
  { flag: "de", location: "Dessau, Germany", short: 'Recital "Clarinetomania"', long: 'at Dessau Marienkirche',
    date: new Date(2019, Months.MAR, 15)
  },
  { flag: "by", location: "Minsk, Belarus", short: "with Belarus National Chamber Orchestra", long: "Conductor: Evgeny Bushkov",
    date: new Date(2019, Months.MAR, 13)
  },
  { flag: "il", location: "Israel", short: "Tour with Astrith Baltsan",
    range: {from: new Date(2019, Months.FEB, 22), to: new Date(2019, Months.MAR, 8)}
  },
  { flag: "cn", location: "China", short: "Tour with Macedonia National Symphony Orchestra",
    range: {from: new Date(2018, Months.DEC, 26), to: new Date(2019, Months.JAN, 6)}
  },
  { flag: "mk", location: "Skopje, Macedonia", short: "with Macedonia National Symphony Orchestra",
    long: 'Conductor: Maestro Vladimir Verbitsky', date: new Date(2018, Months.DEC, 24)
  },
  { flag: "de", location: "Thüringen, Germany", short: "with Loh-Orchester Sondershausen",
    long: 'B. Yusupov - Concert for two clarinets and orchestra ("Images of the soul"). Conductor: Michael Helmrath.',
    range: {from: new Date(2018, Months.NOV, 10), to: new Date(2018, Months.NOV, 11)}
  },
  { flag: "de", location: "Hamburg, Germany", short: "Kammermusikfest",
    long: "Alexander Gurfinkel, Klarinette; Daniel Gurfinkel, Klarinette; Adrien Jurkovic, Violine; Thomas Descamps, Violine; Raphaël Pagnon, Viola; Simon Iachemet, Violoncello",
    date: new Date(2018, Months.NOV, 2)
  },
  { flag: "it", location: "Forli, Italy", short: "Recital",
    long: 'Recital "Clarinetomania", with Elizaveta Blumina (piano)',
    date: new Date(2018, Months.SEP, 16)
  },
  { flag: "de", location: "Tecklenburg, Germany", short: 'Summerwinds Festival',
    date: new Date(2018, Months.JUL, 29)
  },
  { flag: "de", location: "Augsburg, Germany", short: 'Augsburg Festival with Praga Chamber Orchestra',
    date: new Date(2018, Months.JUL, 21)
  },
  { flag: "de", location: "Oberhausen, Germany", short: 'Matinée concert', date: new Date(2018, Months.JUL, 8)},
  { flag: "ua", location: "Odessa, Ukraine", short: 'with Odessa Philharmonic Orchestra', date: new Date(2018, Months.JUN, 24)},
  { flag: "il", location: "Raanana, Israel", short: 'Recital for two clarinets and piano (with Silvia Patru)', date: new Date(2018, Months.MAY, 16)},
  { flag: "il", location: "Haifa, Israel", short: 'with Haifa Symphony Orchestra', range: {from: new Date(2018, Months.MAY, 13), to: new Date(2018, Months.MAY, 14)}},
  { flag: "il", location: "Netanya, Israel", short: 'Recital', date: new Date(2018, Months.MAY, 12)},
  { flag: "nl", location: "Amsterdam, Netherlands", short: 'Concertgebouw', date: new Date(2018, Months.FEB, 5)},
  { flag: "de", location: "Cottbus ,Germany", short: 'with Cottbus Symphony Orchestra', range: {from: new Date(2018, Months.JAN, 12), to: new Date(2018, Months.JAN, 14)}},
  { flag: "mx", location: "Monterrey, Mexico", short: 'Recital', date: new Date(2017, Months.NOV, 29)},
  { flag: "nl", location: "Leiden, Netherlands", range: {from: new Date(2017, Months.OCT, 28), to: new Date(2017, Months.OCT, 29)}},
  { flag: "de", location: "Duisburg, Germany", short: 'with Duisburg String Ensemble', date: new Date(2017, Months.SEP, 14)},
  { flag: "de", location: "Hanover, Germany", short: 'Buxtehude Festival', date: new Date(2017, Months.AUG, 18)},
  { flag: "de", location: "Holzkirchen, Germany", short: 'Flügel Festsaal', date: new Date(2017, Months.JUL, 23)},
  { flag: "ua", location: "Kiev, Ukraine", short: 'with Kiev Chamber Orchestra', date: new Date(2017, Months.JUN, 11)},
  { flag: "ua", location: "Kiev, Ukraine", short: 'with National Symphony Orchestra', date: new Date(2017, Months.JUN, 9)},
  { flag: "il", location: "Jerusalem, Israel", short: 'Recital - Mormon University', date: new Date(2017, Months.MAY, 21)},
  { flag: "il", location: "Israel", short: 'Tour with Estonian Sinfonietta Strings', range: {from: new Date(2017, Months.MAY, 15), to: new Date(2017, Months.MAY, 16)}},
  { flag: "ar", location: "Buenos Aires, Argentina", short: 'with Argentina National Symphony Orchestra', date: new Date(2017, Months.APR, 21)},
  { flag: "ua", location: "Dnepropetrovsk, Ukraine", short: 'with Symphony Orchestra Festival', date: new Date(2017, Months.FEB, 27)},
  { flag: "by", location: "Minsk, Belarus", short: 'with Belarus Symphony Orchestra', date: new Date(2017, Months.FEB, 23)},
  { flag: "de", location: "Berlin, Germany", short: 'Recital', date: new Date(2016, Months.NOV, 22)},
  { flag: "de", location: "Berlin, Germany", short: 'Recital', date: new Date(2016, Months.OCT, 19)},
  { flag: "ge", location: "Tbilisi, Georgia", short: 'International Music Festival "Autumn Tbilisi"', range: {from: new Date(2016, Months.SEP, 29), to: new Date(2016, Months.OCT, 5)}},
  { flag: "de", location: "Berlin, Germany", short: 'Great Hall Konzerthaus - with Schaghajegh Nosrati', date: new Date(2016, Months.SEP, 28)},
  { flag: "gb", location: "London, UK", short: 'Recital with Maxim Vengerov', date: new Date(2016, Months.SEP, 25)},
  { flag: "il", location: "Israel", short: 'with Israel Symphony Orchestra', range: {from: new Date(2016, Months.SEP, 6), to: new Date(2016, Months.SEP, 12)}},
  { flag: "us", location: "Encinitas, USA", short: 'iPalpiti Festival', range: {from: new Date(2016, Months.JUL, 5), to: new Date(2016, Months.JUL, 21)}},
  { flag: "ru", location: "Novosibirsk, Russia", short: 'Trans Siberian Festival with Vadim Repin and Novosibirsk Chamber Orchestra',
    range: {from: new Date(2016, Months.APR, 9), to: new Date(2016, Months.APR, 16)}},
  { flag: "ch", location: "Zurich, Switzerland", short: 'Jewish Song Festival', date: new Date(2016, Months.MAR, 29)},
  { flag: "at", location: "Graz, Austria", short: 'PSALM Easter Festival', date: new Date(2016, Months.MAR, 25)},
  { flag: "il", location: "Jerusalem, Israel", short: 'Georgian Chamber Orchestra (Germany) Israel Tour', date: new Date(2016, Months.FEB, 13)},
  { flag: "il", location: "Kiryat Motzkin, Israel", short: 'Georgian Chamber Orchestra (Germany) Israel Tour', date: new Date(2016, Months.FEB, 11)},
  { flag: "il", location: "Petah Tikva, Israel", short: 'Georgian Chamber Orchestra (Germany) Israel Tour',
    date: new Date(2016, Months.FEB, 8)},
  { flag: "il", location: "Ashkelon, Israel", short: 'Georgian Chamber Orchestra (Germany) Israel Tour',
    date: new Date(2016, Months.FEB, 7)},
  { flag: "il", location: "Ramat Gan, Israel", short: 'Georgian Chamber Orchestra (Germany) Israel Tour',
    date: new Date(2016, Months.FEB, 6)},
  { flag: "de", location: "Ingolstadt, Germany", short: 'with Georgian Chamber Orchestra', date: new Date(2016, Months.FEB, 2)},
  { flag: "ru", location: "Ufa, Russia", short: 'with National symphony orchestra of Republic of Bashkortostan', date: new Date(2015, Months.DEC, 13)},
  { flag: "kz", location: "Astana, Kazakhstan", short: '"Shabit" Festival with Astana Chamber Orchestra',
    range: {from: new Date(2015, Months.NOV, 3), to: new Date(2015, Months.NOV, 8)}},
  { flag: "kz", location: "Astana, Kazakhstan", short: 'with Astana Chamber Orchestra', date: new Date(2015, Months.NOV, 2)},
  { flag: "de", location: "Munster, Germany", short: 'with Marina Baranova (p-no)', date: new Date(2015, Months.OCT, 25)},
  { flag: "de", location: "Hanover, Germany", short: 'with Marina Baranova (p-no)', date: new Date(2015, Months.OCT, 24)},
  { flag: "de", location: "Berlin, Germany", short: 'with Berliner Camerata', date: new Date(2015, Months.AUG, 28)},
  { flag: "de", location: "Kreuth, Germany", short: 'with Armida Quartet (Kreuth Musik Festival)', date: new Date(2015, Months.AUG, 21)},
  { flag: "de", location: "Kreuth, Germany", short: 'Duo and Trio with Michael Gurfinkel (Kreuth Musik Festival)', date: new Date(2015, Months.AUG, 15)},
  { flag: "de", location: "Kreuth, Germany", short: 'with Stuttgart Chamber Orchestra (Kreuth Musik Festival)', date: new Date(2015, Months.AUG, 12)},
  { flag: "de", location: "Kreuth, Germany", short: 'with Chen Reis (Kreuth Musik Festival)', date: new Date(2015, Months.AUG, 11)},
  { flag: "ch", location: "Verbier, Switzerland", short: 'Verbier Festival Orchestra', range: {from: new Date(2015, Months.JUN, 28), to: new Date(2015, Months.AUG, 3)}},
  { flag: "il", location: "Jerusalem, Israel", short: 'Ein Karem', date: new Date(2015, Months.JUN, 27)},
  { flag: "bg", location: "Sofia, Bulgaria", short: 'with Bulgarian National Radio Symphony Orchestra', date: new Date(2015, Months.MAY, 29)},
  { flag: "de", location: "Villa Musica, Germany", short: 'Recital', range: {from: new Date(2015, Months.MAY, 13), to: new Date(2015, Months.MAY, 15)}},
  { flag: "cn", location: "Beijing, China", short: 'Beijing Festival', date: new Date(2015, Months.MAY, 8)},
  { flag: "il", location: "Kfar Saba, Israel", short: 'Recital', date: new Date(2015, Months.APR, 18)},
  { flag: "il", location: "Jerusalem, Israel", short: 'Ein Karem', date: new Date(2015, Months.MAR, 28)},
  { flag: "il", location: "Holon, Israel", short: 'with Felix and Alexandra Nemirovsky', date: new Date(2015, Months.MAR, 23)},
  { flag: "il", location: "Eilat, Israel", short: 'Recital', date: new Date(2015, Months.MAR, 21)},
  { flag: "il", location: "Jerusalem, Israel", short: 'Mormon University', date: new Date(2015, Months.MAR, 8)},
  { flag: "il", location: "Rehovot, Israel", short: 'with Rishonim Quartet', date: new Date(2015, Months.FEB, 28)},
  { flag: "il", location: "Herzliya, Israel", short: 'with Haifa Symphony Orchestra', date: new Date(2015, Months.JAN, 31)},
  { flag: "ee", location: "Tallinn, Estonia", short: 'with Glasperlenspiel Sinfonietta', date: new Date(2014, Months.DEC, 31)},
  { flag: "ee", location: "Tartu, Estonia", short: 'with Glasperlenspiel Sinfonietta', date: new Date(2014, Months.DEC, 31)},
  { flag: "ee", location: "Viljandi, Estonia", short: 'with Glasperlenspiel Sinfonietta', date: new Date(2014, Months.DEC, 29)},
  { flag: "ru", location: "Voronezh, Russia", short: 'with Voronezh Academic Symphonic Orchestra', date: new Date(2014, Months.DEC, 20)},
  { flag: "ru", location: "Kazan, Russia", short: 'Jazz Festival', range: {from: new Date(2014, Months.DEC, 12), to: new Date(2014, Months.DEC, 14)}},
  { flag: "de", location: "Potsdam, Germany", short: 'with Brandenburger Symphoniker Orchestra', date: new Date(2014, Months.NOV, 23)},
  { flag: "de", location: "Brandenburg, Germany", short: 'with Brandenburger Symphoniker Orchestra', range: {from: new Date(2014, Months.NOV, 21), to: new Date(2014, Months.NOV, 22)}},
  { flag: "de", location: "Stendal, Germany", short: 'with Brandenburger Symphoniker Orchestra', date: new Date(2014, Months.NOV, 20)},
  { flag: "il", location: "Ma'ale Adumim, Israel", short: 'Recital', date: new Date(2014, Months.OCT, 30)},
  { flag: "il", location: "Beit Shemesh, Israel", short: 'Recital', date: new Date(2014, Months.OCT, 28)},
  { flag: "il", location: "Jerusalem, Israel", short: 'Ein Karem', date: new Date(2014, Months.OCT, 25)},
  { flag: "il", location: "Ashdod, Israel", short: 'Recital', date: new Date(2014, Months.OCT, 18)},
  { flag: "ge", location: "Tbilisi, Georgia", short: 'with Georgia National Symphony Orchestra', date: new Date(2014, Months.OCT, 5)},
  { flag: "il", location: "Haifa, Israel", short: 'Japan Museum', date: new Date(2014, Months.SEP, 13)},
  { flag: "de", location: "Kreuth, Germany", short: 'Internationales Musik Festival Kreuth', date: new Date(2014, Months.AUG, 15)},
  { flag: "de", location: "Münster, Germany", short: 'with Berliner Camerata', date: new Date(2014, Months.AUG, 10)},
  { flag: "de", location: "Berlin, Germany", short: 'with Berliner Camerata', date: new Date(2014, Months.AUG, 7)},
  { flag: "it", location: "Pietrasanta, Italy", short: 'Pietrasanta International Music Festival', range: {from: new Date(2014, Months.JUL, 26), to: new Date(2014, Months.JUL, 30)}},
  { flag: "il", location: "Jerusalem, Israel", date: new Date(2014, Months.JUN, 28)},
  { flag: "il", location: "Rehovot, Israel", short: 'Recital', date: new Date(2014, Months.JUN, 24)},
  { flag: "il", location: "Jerusalem, Israel", short: 'Jerusalem Israel Festival', date: new Date(2014, Months.JUN, 6)},
  { flag: "il", location: "Petach Tikva, Israel", short: 'The Younger Generation', date: new Date(2014, Months.JUN, 5)},
  { flag: "il", location: "Tel Aviv, Israel", short: 'The Younger Generation', date: new Date(2014, Months.JUN, 4)},
  { flag: "de", location: "Ingolstadt, Germany", short: 'with Georgian Chamber Orchestra', date: new Date(2014, Months.FEB, 20)},
];

export const isInPast = function(item) {
  const today = new Date();
  today.setHours(0,0,0,0);

  const date = item.date;
  if (date != null && date instanceof Date) {
    return date < today;
  }
  const range = item.range;
  if (range != null) {
    return range.to < today;
  }
  return false;
};

const getStartDate = function(item) {
  if (item.date != null && item.date instanceof Date) {
    return item.date;
  }
  if (item.range != null) {
    return item.range.to;
  }
  return null;
};

export const sortDates = function(first, second) {
  const firstDate = getStartDate(first);
  const secondDate = getStartDate(second);
  if (firstDate === null && secondDate === null) {
    return 0;
  } else if (firstDate !== null && secondDate === null) {
    return 1;
  } else if (firstDate === null && secondDate !== null) {
    return -1;
  }
  return (firstDate > secondDate) - (firstDate < secondDate);
};

export const sortDatesDesc = function(first, second) {
  return sortDates(first, second) * (-1);
};

export const getDayAndMonth = function(item) {
  const date = item.date;
  if (date != null && date instanceof Date) {
    return {
      day: date.getDate(),
      month: ThreeLetterMonths[date.getMonth()]
    };
  }
  const range = item.range;
  if (range != null) {
    return {
      day: range.from.getDate(),
      month: ThreeLetterMonths[range.from.getMonth()]
    };
  }
  return {day: '', month: ''};
};

const formatSingleDate = function(date, hideDays) {
  return `${hideDays ? '' : date.getDate() + ' '}${MonthNames[date.getMonth()]}, ${date.getFullYear()}`;
};

export const formatDate = function(item) {
  const date = item.date;
  if (date != null && date instanceof Date) {
    return formatSingleDate(date, false);
  }
  const range = item.range;
  if (range != null) {
    const hideDays = range.hideDays != null && range.hideDays;
    if (range.from.getFullYear() !== range.to.getFullYear()) {
      return `${formatSingleDate(range.from, hideDays)} - ${formatSingleDate(range.to, hideDays)}`
    }
    if (range.from.getMonth() !== range.to.getMonth()) {
      const dayMonthFrom = `${hideDays ? '' : range.from.getDate() + ' '}${MonthNames[range.from.getMonth()]}`;
      const dayMonthTo = `${hideDays ? '' : range.to.getDate() + ' '}${MonthNames[range.to.getMonth()]}`;
      return `${dayMonthFrom} - ${dayMonthTo}, ${range.from.getFullYear()}`
    }
    const dayFrom = range.from.getDate();
    const dayTo = range.to.getDate();
    const days = hideDays ? '' : `${dayFrom}-${dayTo} `;
    return `${days}${MonthNames[range.from.getMonth()]}, ${range.from.getFullYear()}`;
  }
  return '';
};

