import React from 'react';
import { withRouter } from "react-router-dom";

// see: https://reacttraining.com/react-router/web/guides/scroll-restoration or https://stackoverflow.com/a/44992311/1233652
class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);