import React from 'react';
import YoutubeGrid from '../components/YoutubeGrid';

const recordings = [
  {
    title: 'PSALM – FOR FUTURE',
    text: 'Fiddler on the Roof',
    videoId: 'CgYxEp49ZGk',
    date: 'April 2020',
  },
  {
    title: 'Y. Povolotsky - "Jewish Soul Fantasy", "Travelling to Klezmer"',
    text: 'With Chamber Orchestra "Nigun"',
    videoId: 'zWbsC6m3-v0',
    date: 'November 2019',
  },
  {
    title: 'Max Bruch Doppelkonzert für Klarinette und Viola',
    text: 'Wilhelm F. Walz, Teresa Schwamm, Daniel Gurfinkel',
    videoId: '8Z4zQr5Cd1w',
    date: '2018',
  },
  {
    title: 'F. Mendelssohn - Konzertstuck No. 2 Op. 114 for two Clarinets and Orchestra',
    text: 'With Maestro Zubin Mehta, Israel Philharmonic Orchestra.',
    videoId: 'cd03jXYzxgE',
    date: 'November 2010',
  },
  {
    title: 'Rhapsody in Blue for two clarinets and Orchestra',
    videoId: 'g62HgzNJHsU',
    date: 'May 2012',
  },
  {
    title: 'Paganini / Levitas Caprice 24',
    videoId: 'OwAtvBFX84A',
    date: 'April 2011',
  },
  {
    title: 'Carmen Suite',
    videoId: 'ZdSI0iTNjwo',
    date: 'January 2011',
  },
  {
    title: 'Vivaldi - Jazz Four Seasons',
    videoId: 'whZcWtqdTe0',
    date: 'May 2012',
  },
  {
    title: 'Saint-Saens - Introduction and Rondo Capricioso',
    videoId: 'TuIAvW_kOhc',
    date: 'June 2013',
  },
  {
    title: 'B. Yusupov - Images of the Soul',
    videoId: 'blWaFVfaM5g',
    date: 'February 2011',
  },
  {
    title: 'Yuri Povolotsky "Klezmer-Concerto" for 2 Clarinets & Orchestra',
    text: 'with Georgia National Symphony Orchestra',
    videoId: 'mE0AByfBXZA',
  },
  {
    title: 'Shlomo Grohich - Mosaic for two clarinets and bass clarinet',
    text: 'with Georgia National Symphony Orchestra',
    videoId: 'KUZ454ZZuoY',
  },
  {
    title: 'Yuri Povolotsky - Jewish Extravaganza "Pictures of a Forgotten Town"',
    text: 'with Silvia Patru (Piano)',
    videoId: 'ZFkldv3G4wg',
  },
  {
    title: 'Yuri Povolotsky - "Jewish Soul Fantasy"',
    text: 'with Haifa Symphony Orchestra',
    videoId: '3d26ltPqo-M',
  },
  {
    title: 'Yuri Povolotsky - "Remembrance of Nigun"',
    text: 'with Silvia Patru (Piano)',
    videoId: '-SUq0oG7RLM',
  },
];

class RecordingsPage extends React.Component {

  render() {
    return <React.Fragment>
      <YoutubeGrid items={recordings}/>
    </React.Fragment>;
  }
}

export default RecordingsPage;