import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class Footer extends React.Component {
  static getCurrentYear() {
    let year = new Date().getYear();
    if (year < 1900)
      year += 1900;
    return year;
  }

  render() {
    return (
      <footer>
        <hr/>
        <Container>
          <Row>
            <Col><p style={{marginTop: '5px'}}>© 2012-{Footer.getCurrentYear()} Alexander and Daniel Gurfinkel, the clarinet duet. All rights reserved.</p></Col>
            <Col xs lg={2}>
              <a href="https://www.facebook.com/duogurfinkel" target="_blank" rel="noopener noreferrer">
                <img src='/image/social/facebook.png' style={{width: '20px', height: '20px', marginRight: '10px'}} alt="Facebook"/>
              </a>
              <a href="https://twitter.com/duogurfinkel" target="_blank" rel="noopener noreferrer">
                <img src='/image/social/twitter.png' style={{width: '20px', height: '20px', marginRight: '10px'}} alt="Twitter"/>
              </a>
              <a href="https://www.youtube.com/results?search_query=duogurfinkel" target="_blank" rel="noopener noreferrer">
                <img src='/image/social/youtube.png' style={{width: '20px', height: '20px'}} alt="YouTube"/>
              </a>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

export default Footer;