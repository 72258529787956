import React, {Component} from 'react';

/**
 * Default class for Image webp
 */
class Image extends Component {
    /**
     * Check browser webp support
     * TODO: calculate it once
     * @returns {boolean}
     */
    canUseWebP() {
        const elem = typeof document === 'object' ? document.createElement('canvas') : {};

        if (!!(elem.getContext && elem.getContext('2d'))) {
            // was able or not to get WebP representation
            return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
        } else {
            // very old browser like IE 8, canvas not supported
            return false;
        }
    }

    /**
     * Render jsx to html
     * @returns {XML}
     */
    render() {
        const {src, alt, title, style, className, useWebP} = this.props;
        const image = `${src}.${useWebP && this.canUseWebP() ? 'webp' : 'jpg'}`;
        return <img src={image} alt={alt} title={title} style={style} className={className}/>;
    }
}

Image.defaultProps = {
  useWebP: true
};

export default Image;