import React from 'react';
import Gallery from '../gallery/Gallery';

function getImageClass(item) {
  switch (item) {
    case '01':
    case '02':
    case '03':
    case '04':
    case '05':
    case '06':
    case '07':
    case '08':
    case '09':
    case '10':
      return `horizontal-image`;
    case '11':
    case '12':
    case '13':
    case '14':
    case '15':
    case '16':
    case '17':
    case '18':
    case '19':
    case '20':
      return `large-square-image`;
    default:
      return `horizontal-image`;
  }
}

const images = [ "01", "02", "03", "04", "05", "06", "07", "08", "09", "10",
  "11", "12", "13", "14", "15", "16", "17", "18", "19", "20" ].map(item => {
  return {
    src: `/image/gallery/gallery_${item}_.jpg`,
    thumbnail: `/image/gallery/gallery_${item}_tn.jpg`,
    imageClass: getImageClass(item),
  }
});

class GalleryPage extends React.Component {
  static shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  render() {
    return (
      <Gallery images={GalleryPage.shuffleArray(images)}
               enableImageSelection={false}
               rowHeight={200}
               margin={4}
      />
    );
  }
}

export default GalleryPage;