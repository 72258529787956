import React from 'react';
import ReactCountryFlag from "react-country-flag";

import {Concerts, isInPast, formatDate, sortDatesDesc} from '../api/Concerts';
import {calculateHash} from '../utils/StringUtils';

class ConcertsPage extends React.Component {
  static getFlagOrEmptyDiv(flag) {
    if (flag === null || flag.length === 0) {
      return <div style={{width: '100px'}}/>;
    } else {
      return <ReactCountryFlag className="concert-item-flag" code={flag} svg styleProps={{width: '100px', height: '24px'}}/>;
    }
  }

  render() {
    return (
      <div>
        <ul id="concerts-list">
          {
            Concerts.sort(sortDatesDesc).map(item => {
              const header = `${item.location}${item.short != null ? ' - ' + item.short : ''}`;
              const date = formatDate(item);
              const hash = calculateHash(header + date);
              const style = isInPast(item) ? {color: 'rgba(255, 255, 255, 0.6)'} : {};
              return <li className="concert-item" key={hash}>
                {  ConcertsPage.getFlagOrEmptyDiv(item.flag) }
                <div className="concert-item-body" style={style}>
                  <p className="concert-item-header"><b>{header}</b></p>
                  <p className="concert-item-date"><i>{date}</i></p>
                  { item.long && <p className="concert-item-description">{item.long}</p> }
                </div>
              </li>
            })
          }
        </ul>
      </div>
    );
  }
}

export default ConcertsPage;