import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

import Image from '../components/Image';

class BiographyPage extends React.Component {
  static getCarousel() {
    return <Carousel className="biography-gallery" controls={false} indicators={false} pauseOnHover={false}>
      {
        [ "01", "02", "03" ].map(item => {
          return <Carousel.Item key={item}>
            <Image useWebP={false}
                   className="d-block h-100 w-100"
              src={`/image/biography/biography_${item}`}
            />
          </Carousel.Item>
        })
      }
    </Carousel>
  }

  render() {
    return (
      <div style={{textAlign: 'left', marginLeft: '30px', marginRight: '30px'}}>
        <br/><br/>
          <p>{BiographyPage.getCarousel()}The twin brothers Daniel and Alexander Gurfinkel, duo clarinetists, have already established themselves as among today's most important artists.</p>
          <p>Born in 1992, they began their musical education in the year 2000 and by age 12, they were invited by Zubin Mehta to appear as soloists in the annual concert for young musicians with the Israel Philharmonic Orchestra.</p>
          <p>In the recent seasons, Duo Gurfinkel were re-engaged with Mehta and the Israel Philharmonic and performed abroad with the Zagreb Philharmonic, Belgrade Philharmonic, Vienna Tonkünstler Orchestra, National Ukraine Symphony Orchestra,  as well as on tour in France with the Orchestre National des Pays de la Loire, with the Moscow Virtuosi, “Soloists of Moscow”, the Israel Symphony Orchestra Rishon Le Zion, Jerusalem Symphony orchestra, la Primavera Chamber Orchestra, Haifa Symphony Orchestra, Georgian Chamber Orchestra Ingolstadt, Captain Symphony Orchestra SA, Wuerttemberg Chamber Orchestra Heilbronn, with Mozartiana de Rome chamber orchestra at the Vatican, Bulgaria National Orchestra Radio and TV, Georgian National Symphony Orchestra and the Brandenburg Symphonics.</p>
          <p>Duo Gurfinkel’s was/is invited to perform in Switzerland (Mendelssohn concerto) at the “Moscow Meets Friends” festival with the Moscow Virtuosi Chamber Orchestra under Vladimir Spivakov, in Los Angeles under the auspices of Zubin Mehta, with Yuri Bashmet and the Moscow Soloists at the Tchaikovsky Hall, in Ekaterinburg and with the Capella Istopolitana at the Rheingau Festival. Additionally, the duo has given concerts across the USA (Detroit, New York, and Chicago) as well as in Bejing, Hong Kong, Slovenia, Italy, Russia, Germany, Azerbaijan, Slovakia, Croatia, Austria, and South Africa. Furthermore, Duo Gurfinkel recently have performed several world premieres. Among them are Rashid Kalimulin’s concerto for two clarinets and orchestra (with the Leipzig Symphonics at Gewandhaus Leipzig 2013), the concerto by Gil Shohat (with the Jerusalem Symphony Orchestra) and Doron Toister’s klezmer concerto (with the Raanana Symphony Orchestra Israel).</p>
          <p>In March 2013, the duo won the 3rd prize at the 18th International Chamber Music Competition in Illzach, France.</p>
          <p>Daniel and Alexander Gurfinkel preserve a rich family tradition of three generations. Their grandfather Arkady Gurfinkel was a well-known clarinet player, teacher, and arranger, while their father, Michael Gurfinkel, the principal of the Israel Symphony Orchestra and Israeli Opera is one of the leading clarinet players in Israel and abroad.</p>
          <p>During the season 2015/2016 Duo Gurfinkel was not only come back to the International Musik Festival Kreuth, but it was also performing in Verbier, Berlin (Konzerthaus), Ingolstadt, Kiev with the Stuttgart Chamber Orchestra, Jerusalem Symphony Orchestra (USA Tour), National Symphony Orchestra of Ukraine, Olaf Bär, Olga Scheps, and others.</p>
          <p>The duo also received invitation to perform at iPalpiti 2016 Festival in Encinitas, US.</p>
          <p>In November 2016, the duo won the 2nd prize at the 18th International Chamber Music Competition "Concorso Fiorindo" in Nichelino, Italy.</p>
          <p>In February 2017 Duo Gurfinkel will record a new CD Program ( Duo with Orchestra) and perform with Haifa Symphony orchestra, Kiev Chamber Orchestra, Duisburg String Ensemble, Belarus Symphony Orchestra and others.</p>
          <p>In February 2018 the Duo will take a part at the Chamber Music project in Concertgebouw Amsterdam.</p>
      </div>
    );
  }
}

export default BiographyPage;