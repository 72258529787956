export const Path = {
  HOME: "/",
  BIOGRAPHY: "/biography",
  TRIO: '/trio',
  REPERTOIRE: "/repertoire",
  CONCERTS: "/concerts",
  REVIEWS: "/reviews",
  RECORDINGS: "/recordings",
  GALLERY: "/gallery",
  CONTACT: "/contact",
};