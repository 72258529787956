import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';

import App from './App';
import registerServiceWorker from './registerServiceWorker';
import ScrollToTop from './components/ScrollToTop';

import history from "./history";
import ReactGA from 'react-ga';

ReactGA.initialize('UA-45391386-1');

ReactDOM.render(
	<Router history={history}>
		<ScrollToTop>
			<App />
		</ScrollToTop>
	</Router>
	, document.getElementById('root'));
registerServiceWorker();