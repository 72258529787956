import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import YouTube from 'react-youtube';

class YoutubeGrid extends React.Component {
  getYouTube(videoId) {
    const opts = {
      height: '230',
      width: '100%',
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
        iv_load_policy: 3,
        modestbranding: 1,
        rel: 0,
      }
    };
    return <div className="youtube-container">
      <YouTube videoId={videoId} opts={opts}/>
    </div>;
  }

  render() {
    return <div className="recordings">
      {
        this.props.items.map((item, index) => {
          return <Card className="bg-dark text-white recordings-card" key={index}>
            {this.getYouTube.bind(this, item.videoId)()}
            <Card.Body>
              <Card.Text style={{color: 'var(--color-main)'}}>
                {item.title}
              </Card.Text>
              {
                item.text && <Card.Text>
                  {item.text}
                </Card.Text>
              }
            </Card.Body>
            {
              item.date && <Card.Footer>
                <small className="text-muted">{item.date}</small>
              </Card.Footer>
            }
          </Card>;
        })
      }
    </div>;
  }
}

YoutubeGrid.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string,
      videoId: PropTypes.string.isRequired,
      date: PropTypes.string
    })
  ).isRequired,
};

export default YoutubeGrid;