import React from 'react';
import '../style/Trio.css';
import YoutubeGrid from '../components/YoutubeGrid';

const content = [
  {
    text: "The DIAMOND Trio was founded in 2016 by the pianist Silvia Patru and the clarinetists and twin brothers Daniel and Alexander Gurfinkel. The three young artists got to know each other during their studies at the University of Music Hanns Eisler in Berlin. In addition to a tour in Israel, the DIAMOND Trio is guest of renowned festivals in Germany such as the Internationales Musikfest Kreuth am Tegernsee, the concert series Meister von morgen - Dr. Ing. Carl Dörken Stiftung and Klassik um eins and Klassik um sechs in Berlin. Together with guest of honor Albrecht Meyer they performed in 2017 in favor of the America - Israel Cultural Foundation in Berlin."
  },
  {
    image: '/image/trio/trio_01.jpg'
  },
  {
    text: "The Westfalenpost praises: \"The Diamond Trio with Daniel and Alexander Gurfinkel, clarinet, and Sivia Patru, piano, formed a brilliant team. At the \"Carnival of Venice\" by Alamiro Giampieri (1893-1963), arranged by R. Mozes, the audience would have liked to sing along. \"My hat, it has three corners\" was imaginatively varied as a theme, as a waltz, as an elegy, dramatic, poetic, even taken over by the piano, played with in a virtuoso way. The audience went wild. All musicians played together to the point exactly in virtuoso passages. Longing lamentations were followed by energetic folkloric music. The audience cheered and begged for encore.\""
  },
  {
    image: '/image/trio/trio_02.jpg'
  },
  {
    text: "The Münchner Merkur raves: \"The Gurfinkel twins were accompanied by pianist Silvia Patru from Berlin. The way she formed the basis for the interaction of the brothers with her sometimes symphonical, sometimes chamber musical, sometimes jazzy play, was fascinating. She had covered their back, in a manner of speaking. With Yuri Povolotsky's Best of \"Traveling to Klezmer\" the Gurfinkels went on a journey into all moods of Klezmer music. The clarinets laughed, teased one another, affirmed the life, reflected sensitively, sadly complained, differentiated, yearning, danced impetuously polka or passionately tango. These were twelve thrilling pieces, from \"Nigun\" to \"Balagula\", \"Freilach in Rhythm of Swing\" to \"Hassidic Dance\", which mirrored the Yiddish life.\""
  },
  {
    image: '/image/trio/trio_03.jpg'
  },
];

const recordings = [
  {
    title: 'Fantasie on themes from the opera "Carmen" by G. Bizet',
    text: 'Alexander Rosenblatt arr. by Uri Brener',
    videoId: 'Jm8okHa0MmQ',
    date: 'January 2020',
  },
  {
    title: 'The Best from Album Travelling to Klezmer: Nigun',
    text: 'Yuri Povolotsky',
    videoId: 'd0O9Uh8ZUpY',
    date: 'January 2020',
  },
  {
    title: 'Mendelssohn Konzertstück in f-Moll, op. 113',
    videoId: 'DbsbSdkGol8',
    date: 'January 2020',
  }
];

class TrioPage extends React.Component {
  static generateContent(textItem, imageItem) {
    return <React.Fragment>
      <span style={{textAlign: 'left'}}> {textItem.text} </span>
      <img style={{
        width: '100%',
        height: 'auto',
        maxWidth: '460px',
      }} src={imageItem.image} alt="Trio"/>
    </React.Fragment>
  }

  render() {
    // convert array to pairs and then create <li> for each pair
    return (
      <div>
        <div className="trio"  style={{marginBottom: '100px'}}>
          <ul>
            {
              content.reduce(function(result, value, index, array) {
                if (index % 2 === 0)
                  result.push(array.slice(index, index + 2));
                return result;
              }, []).map((pair, index) =>
                (<li key={index}>
                  { TrioPage.generateContent(pair[0], pair[1]) }
                </li>))
            }
          </ul>
        </div>
        <YoutubeGrid items={recordings}/>
      </div>
    );
  }
}

export default TrioPage;