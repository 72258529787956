import React from 'react';

const withOrchestra = [
  { composer: "Mendelssohn", piece: "Konzertstück 1 op. 113", target: "two clarinets and orchestra", original: true},
  { composer: "Mendelssohn", piece: "Konzertstück 2 op. 114", target: "two clarinets and orchestra", original: true},
  { composer: "R. Strauss", piece: "Duet-Concertino", target: "two clarinets, harp and strings"},
  { composer: "M. Bruch", piece: "Concerto", target: "two clarinets and symphony orchestra"},
  { composer: "Krommer", piece: "Concerto op. 35", target: "two clarinets and orchestra"},
  { composer: "Krommer", piece: "Concerto op. 91", target: "two clarinets and orchestra"},
  { composer: "Backofen", piece: "Concertante, Opus 10", target: "two clarinets and orchestra"},
  { composer: "Saint-Saëns", piece: "Rondo Capricioso", target: "two clarinets and orchestra", arr: "R. Feigelson"},
  { composer: "Bizet", piece: "Carmen Fantasia", target: "two clarinets and symphony orchestra", arr: "E. Levitas"},
  { composer: "Bizet / Levitas", piece: "Carmen 21", target: "two clarinets, string orchestra, drums and double bass"},
  { composer: "Weber", piece: "Grand duo Concertante", target: "two clarinets and orchestra"},
  { composer: "Ponchielli", piece: "Grand Duo", target: "two clarinets and orchestra", original: true},
  { composer: "Mozart", piece: "Sinfonia Concertante in E-flat Major K.364", target: "two clarinets and orchestra", arr: "A. Gurfinkel"},
  { composer: "Prokofiev / Levitas", piece: "Suite from Romeo and Juliet", target: "two clarinets and orchestra"},
  { composer: "Gershwin / A.Povolotsky", piece: "Fantasy on theme Rhapsody in blue", target: "two clarinets and symphony orchestra"},
  { composer: "J.S. Bach", piece: "Concerto", target: "two clarinets in C minor BWV 1060"},
  { composer: "Händel", piece: "Trio-Sonata", target: "two clarinets and string orchestra in g minor op. 2 mo.7"},
  { composer: "Y. Povolotsky", piece: "The Klezmer Concerto", target: "two clarinets and symphony orchestra"},
  { composer: "E. Levitas", piece: "Remembering Benny Goodman Concerto", target: "two clarinets and symphony orchestra"},
  { composer: "Y. Levitin", piece: "Concerto", target: "two clarinets and symphony orchestra", original: true},
  { composer: "G. Palmer", piece: "It Takes Two - Concerto", target: "two clarinets and orchestra", original: true},
  { composer: "E. Podgaits", piece: "Concert", target: "two clarinets and orchestra"},
  { composer: "E. Sokolovsky", piece: "Concerto Grosso", target: "two clarinets and string orchestra"},
  { composer: "Gershwin / Reznik", piece: "Porgy and Bess suite", target: "two clarinets, string orchestra, drums and double bass"},
  { composer: "Gronich", piece: "Mosaic", target: "three clarinets and chamber orchestra", original: true},
  { composer: "Hoffmeister", piece: "Concerto Eb Major", target: "two clarinets and orchestra", original: true},
  { composer: "R. Kalimullin", piece: "Concert", target: "two clarinets and orchestra"},
  { composer: "Mozart", piece: "Divertimento on themes from 'Don Giovanni' and 'The Marriage of Figaro'"},
  { composer: "Sarasate", piece: "Navarra", target: "two clarinets and orchestra"},
  { composer: "Giampieri", piece: "Capriccio Variato IL CARNEVALE DI VENEZIA", arr: "R. Mozes"},
  { composer: "Aby Rojze", piece: "Variations Raices", target: "two clarinets and orchestra"},
  { composer: "Stamitz", piece: "Concerto", target: "two clarinets and orchestra"},
  { composer: "Tchaikovsky", piece: "The Seasons", target: "two clarinets and orchestra"},
  { composer: "A. Vivaldi", piece: "The Jazz Clarinets Seasons", target: "two clarinets, jazz trio and strings orchestra", arr: "A. Reznik"},
  { composer: "F. E. Bach", piece: "Solfegiato", target: "two clarinets, jazz trio and strings orchestra", arr: "A. Reznik"},
  { composer: "J. Bock / Levitas", piece: "Fiddler on the roof (clarinets on the roof)", target: "two clarinets and string orchestra"},
  { composer: "Dulitsky", piece: "Hasidim Rhapsody", target: "two clarinets, string orchestra, drums and double bass"},
  { composer: "B. Yusupov", piece: "Concerto", target: "two clarinets and symphony orchestra"},
];

const miniatureForTwoAndThree = [
  { composer: "W. Mozart", piece: "Divertimento on themes from 'Don Giovanni' and 'The Marriage of Figaro'", target: "two clarinets and bass clarinet"},
  { composer: "G. Rossini", piece: "Aria Rozini 'Una voce poco fa' from the opera 'The Barber of Seville'", target: "two clarinets", arr: "Laszlo Gaber"},
  { composer: "J.S. Bach / C. Gounod", piece: "Ave Maria", target: "two clarinets", arr: "A. Gurfinkel"},
  { composer: "J. Aubert", piece: "Gigue", target: "two clarinets", arr: "V. Ivangulov"},
  { composer: "C. Saint-Saens", piece: "The Swan from 'The Carnival of the Animals' suite", target: "two clarinets", arr: "A. Gurfinkel"},
  { composer: "N. Paganini", piece: "Caprice No.17", target: "two clarinets", arr: "E. Levitas"},
  { composer: "A. Giampieri", piece: "Il Carnevale di Venezia capricious variate", target: "two clarinets", arr: "Robert Mozes"},
  // Break
  { composer: "L. Beethoven", piece: "Variations from the opera 'The Marriage of Figaro' 'La ci darem la mano'", target: "two clarinets and bass clarinet", arr: "M. Reihtman"},
  { composer: "J. Massenet", piece: "Meditation", target: "two clarinets", arr: "A. Gurfinkel"},
  { composer: "P. Tchaikovsky", piece: "Suite from the ballet 'The Nutcracker'", target: "two clarinets and bass clarinet"},
  { composer: "G. Gershwin", piece: "Pearls from 'Porgy and Bess'", target: "two clarinets", arr: "A. Reznik"},
  { composer: "F. Poulenc", piece: "Sonata", target: "two clarinets"},
  { composer: "Folk music", piece: "Ciacarlia", target: "two clarinets", arr: "M. Dulitsky"},
  { composer: "R. Mozes", piece: "Fantasia 'Fiddler on the Roof'", target: "two clarinets and bass clarinet"},
];

const miniatureForTwo = [
  { composer: "G. Handel", piece: "Passacaglia", target: "two clarinets", arr: "R. Feigelson"},
  { composer: "W. Mozart", piece: "Rondo from Sonata for Violin and Piano C-Dur", target: "two clarinets", arr: "S. Kohler"},
  { composer: "G. Rossini", piece: "Aria Rozini 'Una voce poco fa' from the opera 'The Barber of Seville'", target: "two clarinets", arr: "Laszlo Gaber"},
  { composer: "G. Rossini", piece: "Figaro's Aria", target: "two clarinets", arr: "Laszlo Gaber"},
  { composer: "J.S. Bach / C. Gounod", piece: "Ave Maria", target: "two clarinets", arr: "A. Gurfinkel"},
  { composer: "J. Aubert", piece: "Gigue", target: "two clarinets", arr: "V. Ivangulov"},
  { composer: "C. Saint-Saens", piece: "The Swan from 'The Carnival of the Animals' suite", target: "two clarinets", arr: "A. Gurfinkel"},
  { composer: "N. Paganini", piece: "Caprice No.9", target: "two clarinets", arr: "E. Levitas"},
  { composer: "N. Paganini", piece: "Caprice No.17", target: "two clarinets", arr: "E. Levitas"},
  { composer: "N. Paganini", piece: "Caprice No.24", target: "two clarinets", arr: "E. Levitas"},
  // Break
  { composer: "F. Poulenc", piece: "Sonata ", target: "two clarinets"},
  { composer: "J. Brahms", piece: "'Andante un poco adagio' second movement from Sonata for Clarinet and Piano", target: "two clarinets", arr: "J. Beesl"},
  { composer: "A. Giampieri", piece: "'Il Carnevale di Venezia' capricious variate", target: "two clarinets", arr: "Robert Mozes"},
  { composer: "J. Massenet", piece: "Meditation", target: "two clarinets", arr: "A. Gurfinkel"},
  { composer: "E. Denisov", piece: "Second movement from Sonata", target: "clarinet"},
  { composer: "Pastikian", piece: "Solo de Concourse", target: "clarinet"},
  { composer: "G. Gershwin", piece: "Pearls from 'Porgy and Bess'", target: "two clarinets", arr: "A. Reznik"},
  { composer: "Folk music", piece: "Ciacarlia", target: "two clarinets", arr: "M. Dulitsky"},
  { composer: "Klezmer Suite", piece: "Mizvah and Dance", target: "two clarinets", arr: "R. Mozes"},
];

const chamberMusicForPiano = [
  { composer: "M. Glinka", piece: "Pathetique trio", target: "clarinets and piano"},
  { composer: "L. Beethoven", piece: "Trio in F-dur", target: "clarinets and piano"},
  { composer: "J. Brahms", piece: "Trio in A minor Op. 114", target: "two clarinets and piano"},
  { composer: "M. Bruch", piece: "4 Pieces", target: "two clarinets and piano"},
  { composer: "W. Mozart", piece: "Kegelstatt trio", target: "two clarinets and piano"},
  { composer: "F. Mendelssohn", piece: "Trio No. 1 in D minor Op. 49", target: "two clarinets and piano"},
  { composer: "S. Rachmaninoff", piece: "Trio Elegiaque in D minor Op. 9", target: "two clarinets and piano"},
  { composer: "G. Handel", piece: "Trio Sonata g-moll Op. 2", target: "two clarinets and piano"},
  { composer: "S. Prokofiev", piece: "Overture on Hebrew Themes", target: "two clarinets and piano"},
  { composer: "A. Khachaturian", piece: "Trio", target: "clarinet, violin and piano"},
  { composer: "C. M. Weber", piece: "Gran Duo Concertante", target: "two clarinets and piano"},
  { composer: "G. Bizet", piece: "Carmen Fantasia", target: "two clarinets and piano"},
  { composer: "J. Bock / Levitas", piece: "Fiddler on the Roof", target: "two clarinets and piano"},
  { composer: "S. Prokofiev / Levitas", piece: "Suite from 'Romeo and Juliet'", target: "two clarinets and piano"},
  { composer: "A. Giampieri", piece: "'Il Carnevale di Venezia' capricious variate", arr: "R. Mozes"},
  { composer: "C. Saint-Saens", piece: "Rondo Capriccioso", target: "two clarinets and piano"},
];

const jazz = [
  { composer: "G. Bizet / Levitas", piece: "Carmen 21", target: "two clarinets, piano, drums and double bass"},
  { composer: "M. Dulitsky", piece: "Hasidim Rhapsody", target: "two clarinets, piano, drums and double bass"},
  { composer: "G. Gershwin / Reznik", piece: "Porgy and Bess suite", target: "two clarinets, piano, drums and double bass"},
];

const chamberMusicForQuartet = [
  { composer: "W. Mozart", piece: "Quintet", target: "clarinet and quartet"},
  { composer: "W. Mozart", piece: "Quartet 1inb.k317b", target: "clarinet, violin, viola and cello"},
  { composer: "J. Brahms", piece: "Quintet", target: "clarinet and quartet"},
  { composer: "C. M. Weber", piece: "Quintet", target: "clarinet and quartet"},
  { composer: "G. Shohat", piece: "Quintet", target: "clarinet and quartet"},
];

class RepertoirePage extends React.Component {
  createRow(item, index) {
    const original = item.original ? '(Original) ' : '';
    const target = item.target != null ? `for ${item.target} ` : '';
    const arrange = item.arr != null ? '(arr. by ' + item.arr + ')' : '';
    const text = ` - "${item.piece}" ${original}${target}${arrange}`;
    return <p style={{fontSize: '14px'}} key={index}>
      <b>{item.composer}</b>{text}
    </p>;
  }

  render() {
    return (
      <div style={{textAlign: 'left', lineHeight: '1', marginLeft: '30px', marginRight: '30px'}}>
        <br/>
        <h2 style={{fontSize: '22px', color: 'var(--color-main)'}}>With Orchestra</h2>
        <br/>
          { withOrchestra.map((item, index) => this.createRow(item, index))}
        <br/><br/>
        <h2 style={{fontSize: '22px', color: 'var(--color-main)'}}>The best miniature for two and three clarinets solo</h2>
        <br/>
          { miniatureForTwoAndThree.map((item, index) => this.createRow(item, index))}
        <br/><br/>
        <h2 style={{fontSize: '22px', color: 'var(--color-main)'}}>The best miniature for two clarinets solo</h2>
        <br/>
          { miniatureForTwo.map((item, index) => this.createRow(item, index))}
        <br/><br/>
        <h2 style={{fontSize: '22px', color: 'var(--color-main)'}}>Chamber Music for two Clarinets and Piano</h2>
        <br/>
          { chamberMusicForPiano.map((item, index) => this.createRow(item, index))}
        <br/><br/>
        <h2 style={{fontSize: '22px', color: 'var(--color-main)'}}>Jazz Program</h2>
        <br/>
          { jazz.map((item, index) => this.createRow(item, index))}
        <br/><br/>
        <h2 style={{fontSize: '22px', color: 'var(--color-main)'}}>Chamber Music with Quartet and Quintet</h2>
        <br/>
          { chamberMusicForQuartet.map((item, index) => this.createRow(item, index))}
        <br/>
      </div>
    );
  }
}

export default RepertoirePage;