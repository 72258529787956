import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Jumbotron from 'react-bootstrap/Jumbotron'

import {Concerts, isInPast, formatDate, getDayAndMonth, sortDates} from '../api/Concerts';
import {calculateHash} from '../utils/StringUtils';
import Image from '../components/Image';
import Testimonial from "../components/Testimonial";

class HomePage extends React.Component {
	constructor() {
		super();
		this.events = Concerts.sort(sortDates).filter(item => !isInPast(item)).slice(0, 3);
    this.twitterRef = React.createRef();
	}

  componentDidMount() {
		// see: https://stackoverflow.com/a/33377622/1233652
		if (window.twttr != null) {
      window.twttr.widgets.load(this.twitterRef.current);
		}
	}

	render() {
    const review = {
			text: "Daniel and Alexander Gurfinkel are two of the most talented musicians among the young generation of Israelis today",
      title: 'Zubin Mehta',
			subtitle: 'Maestro',
      image: '/image/reviews/zubin.png',
    };

    return (
    	<div style={{position: 'relative'}}>
				<Carousel controls={false} indicators={false} pauseOnHover={false}>
					{
						[ "001", "002", "003", "004", "005", "006", "007", "008", "009" ].map(item => {
							return <Carousel.Item key={item}>
								<Image
									className="d-block w-100"
									src={`/image/main_${item}`}
								/>
							</Carousel.Item>
						})
					}
				</Carousel>
				<Jumbotron className="home-banner" style={{padding: '0'}}>
					<div className="banner-container">
						<div className="banner-column">
							<span className="banner-column-header">Featured Reviews</span>
							<div className="banner-underline"/>
							<Testimonial {...review} mainTextStyle={{fontSize: '15px'}}/>
							<div style={{marginTop: '20px'}} ref={this.twitterRef}>
								<a href="https://twitter.com/duogurfinkel"
									 className="twitter-timeline"
									 data-height="220"
									 data-show-count="false"
									 data-show-screen-name="false"
									 aria-hidden="true"
								> </a>
							</div>
						</div>
						<div className="banner-column">
							<div style={{display: 'flex', flexDirection: 'row'}}>
								<div className="banner-column-header">Events</div>
								<div className="banner-more-events" onClick={() => this.props.history.push('/concerts')}>See all events</div>
							</div>
							<div className="banner-underline"/>
							{ this.events.map(event => {
									const dayAndMonth = getDayAndMonth(event);
									const date = formatDate(event);
                	const hash = calculateHash(event.location + event.short + date);
									return <div className="event-item" key={hash}>
										<div className="event-item-calendar-date">
											<p style={{fontSize: '18px', marginBottom: '2px', marginTop: '8px'}}>{dayAndMonth.month}</p>
											<p style={{fontSize: '35px', marginBottom: '10px', lineHeight: '40px'}}>{dayAndMonth.day}</p>
										</div>
										<div className="event-item-body">
											<p className="event-item-text">{date}</p>
											<p className="event-item-text">{event.location}</p>
											<p className="event-item-text">{event.short}</p>
										</div>
									</div>;
								})
							}
						</div>
					</div>
				</Jumbotron>
			</div>
		);
	}
}

export default HomePage;