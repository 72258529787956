import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import HomePage from '../pages/HomePage';
import BiographyPage from '../pages/BiographyPage';
import TrioPage from '../pages/TrioPage';
import RepertoirePage from '../pages/RepertoirePage';
import ConcertsPage from '../pages/ConcertsPage';
import ReviewsPage from '../pages/ReviewsPage';
import RecordingsPage from '../pages/RecordingsPage';
import GalleryPage from '../pages/GalleryPage';
import ContactPage from '../pages/ContactPage';
import {Path} from "../Const";
import history from "../history";
import withTracker from "../analytics/withTracker";

class Main extends React.Component {
  render() {
    return (
      <main className="app-content">
        <TransitionGroup>
          <CSSTransition key={history.location.key}
            timeout={{ enter: 300, exit: 300 }}
            classNames={'fade'}
          >
            <Switch location={history.location}>
              <Route exact path={Path.HOME} component={withTracker(HomePage)}/>
              <Route path={Path.BIOGRAPHY} component={withTracker(BiographyPage)}/>
              <Route path={Path.TRIO} component={withTracker(TrioPage)}/>
              <Route path={Path.REPERTOIRE} component={withTracker(RepertoirePage)}/>
              <Route path={Path.CONCERTS} component={withTracker(ConcertsPage)}/>
              <Route path={Path.REVIEWS} component={withTracker(ReviewsPage)}/>
              <Route path={Path.RECORDINGS} component={withTracker(RecordingsPage)}/>
              <Route path={Path.GALLERY} component={withTracker(GalleryPage)}/>
              <Route path={Path.CONTACT} component={withTracker(ContactPage)}/>
              <Redirect path="*" to={Path.HOME}/>
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </main>
    );
  }
}

export default Main;