import React from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

import Testimonial from "../components/Testimonial";
import {Months, MonthNames} from '../utils/Const';

const ReviewType = {
  TESTIMONIAL: 'TESTIMONIAL',
  TEXT_ONLY: 'TEXT_ONLY',
  TEXT_AND_IMAGE: 'TEXT_AND_IMAGE',
};

const reviews = [
  {
    type: ReviewType.TESTIMONIAL,
    text: "The twin brothers showed they can do anything they want with their clarinets! Great virtuosity, a perfect harmony and a sublime technique!",
    title: 'Pietrasanta in Concerto',
    date: new Date(2014, Months.JUL, 29),
    image: '/image/reviews/pietrasanta.jpg',
  },
  {
    type: ReviewType.TEXT_ONLY,
    text: [
      '..."The Matinee on Sunday brought two surprises: the Slovenian Philharmonic Orchestra, and the Israeli Clarinetists Daniel and Alexander Gurfinkel. The twins, only 17 years young, have all one would wish for in terms of technique and musicianship. They differentiated beautifully and subtly in such diverse pieces as Mendelssohn’s “Konzertstück” op. 114 and Gershwin’s “Rhapsody in Blue”...'
    ],
    title: 'Oberösterreichische Nachrichten',
    date: new Date(2009, Months.OCT, 20),
  },
  {
    type: ReviewType.TEXT_ONLY,
    text: [
      'The clarinetists twin brothers from Israel, Daniel and Alexander Gurfinkel are under 15 years age. However, they are already virtuosi and are the new generation and future clarinetists... Daniel & Alex Gurfinkel performed Bizet\'s "CARMEN" Fantasy with soft sound, transparency, and seriousness… Being serious clarinetists they are not deprived of "showman"...',
    ],
    title: '"Moscow News" Conductor Yuri Basmet',
    date: new Date(2007, Months.FEB, 1),
    hideDays: true,
  },
  {
    type: ReviewType.TESTIMONIAL,
    text: 'They differentiated beautifully and subtly in such diverse pieces as Mendelssohn’s “Konzertstück” op. 114 and Gershwin’s “Rhapsody in Blue”',
    title: 'Nachrichten',
    date: new Date(2009, Months.OCT, 20),
    image: '/image/reviews/nachrichten.png',
  },
  {
    type: ReviewType.TEXT_ONLY,
    text: [
      'What came after the intermission was unheard of – the 17 year old Clarinet-twins Daniel and Alexander Gurfinkel are celebrating a meteoric career which is guided by none other than Zubin Mehta himself. It was simply phenomenal to hear them upgrade Mendelssohn’s uninspired Konzertstück No. 2 in d minor with freshness, vitality, and virtuosity. In Gershwin’s jazzy “Rhapsody in Blue” they were a brilliant shining soloist duo',
    ],
    title: 'Slowenian Philharmonic Orchestra Conductor: Gabriel Chmura',
    secondLineTitle: 'Brucknerhaus Linz, Austria',
    date: new Date(2009, Months.OCT, 18),
  },
  {
    type: ReviewType.TESTIMONIAL,
    text: 'It was simply phenomenal to hear them upgrade Mendelssohn’s uninspired Konzertstück No. 2 in d minor with freshness, vitality and virtuosity',
    title: 'Brucknerhaus Linz',
    date: new Date(2009, Months.OCT, 18),
    image: '/image/reviews/brucknerhaus.gif',
  },
  {
    type: ReviewType.TEXT_ONLY,
    text: [
      '... "The Gurfinkel twins brought out this concert piece’s different moods – its intensity, lilting lyricism and wit – in a myriad of coloristic possibilities. Julia Gurvich gave interest and presence to the piano part.”...',
      'With impressive instrumental mastery and natural and instinctive responsiveness, the Gurfinkel brothers, however, presented the drama of the piece, its temperament and its Brahmsian underlying nostalgia...',
      'The arrangement we heard of this piece was by Arkady Gurfinkel, Daniel and Alexander’s grandfather, who was present at the Ein Kerem concert. In this intimate work, the artists created its autumnal mood with wonderful byplay between the clarinets, presenting Brahms’ emotional palette. With full-blown expression and fragile moments, the artists evoked the pensive tranquility of the Andante Grazioso second movement, the hearty waltz and Ländler of the third movement and the spirited, virtuosic gypsy music of the final movement, with its feisty cross rhythms. The Gurfinkel brothers colored the two late Brahms works on this program with warm, mellifluous lower register hues so suitable to this music of affection, yearning and introspection, contrasted by bright, legato cantabile playing in upper registers.'
    ],
    title: 'Pamela Hickman',
    subtitle: {
      url: 'https://pamelahickmansblog.blogspot.co.il/2013/12/duo-gurfinkel-and-julia-gurvich-in.html',
      text: 'Blog'
    },
    date: new Date(2013, Months.DEC, 28),
  },
  {
    type: ReviewType.TESTIMONIAL,
    text: 'The Gurfinkel twins brought out this concert piece’s different moods – its intensity, lilting lyricism and wit',
    title: 'Pamela Hickman',
    date: new Date(2013, Months.DEC, 28),
    image: '/image/reviews/pamela_hickman.jpg',
  },
  {
    type: ReviewType.TEXT_ONLY,
    text: [
      'The programme started with a favourite overture of mine, Weber’s “Oberon”, in a middling version. But things promptly picked up when the twin clarinet players Daniel and Alexander Gurfinkel showed their fantastic technique and beautiful timbre in two works. First, the Concert Piece (not Concerto) Nº 1, op.113, by Mendelssohn (originally for clarinet and corno di bassetto — a clarinet a third lower — and piano), a charming and typical score fast-slow-fast. The orchestration may be by Mendelssohn and at least in this version the music was a BA première.',
      'And so was the following work: “De mis raíces” (“From my roots”), Concert variations (not a concerto) for two clarinets and orchestra, Op.41, by Aby Rojze, who was a violinist of the NS during more than four decades until his retirement some years back and during his mature years decided to start a parallel career as a composer. It’s only fair that his beloved orchestra should give him a place in their programming. These variations are tonal and pleasant, with a curious orchestration of strings, trumpets and percussion and virtuoso interventions for the clarinets. The music indeed refers to his roots, which are Jewish and Argentine, so we hear a milonga but also parts that refer to the klezmer tradition, and the main melody sounds solemn and religious both at the beginning and the end. Wonderful playing by the twins, who added as encores two klezmer pieces, and committed accompaniment by conductor and orchestra. Rojze saluted the audience.'
    ],
    title: '"Buenos Aires Herald"',
    subtitle: {
      url: 'http://www.buenosairesherald.com/article/225463/ups-and-downs-of-national-symphony-ministerial-bureaucracy-cck-logistics',
      text: 'Reference',
    },
    date: new Date(2017, Months.APR, 28),
  },
  {
    type: ReviewType.TEXT_AND_IMAGE,
    text: [
      'Es fácil esbozar una sonrisa al escuchar cómo los clarinetistas Daniel y Alexander Gurfinkel se unen en perfecta sincronía con una comprensión musical recíproca que sólo se da al compartir el escenario por toda la vida.',
      'Ese dominio escénico se reafirmó anoche en el concierto que ofrecieron los gemelos de Israel para cerrar con virtuosismo y júbilo la Temporada de Otoño Parnassós 2017.',
      'Los hermanos de 25 años de edad ya habían venido a la Ciudad en dos ocasiones, en el 2011 y el 2006, en esta última como preadolescentes y junto con su padre, el también clarinetista Michael Gurfinkel.',
      'Ahora, ante un público de unas 150 personas en el Auditorio San Pedro, el dúo mostró cuánto ha crecido musicalmente con un programa que inició con un Konzertstück de Mendelssohn, con acompañamiento de la pianista Nargiza Kamilova, donde de inmediato lució su elegancia y destreza.',
      'Los hermanos siguieron con dos piezas sin acompañamiento, un "Rondó" de Mozart, donde expusieron su control de timbre y dinámica, y el aria "Largo al factotum", de "El Barbero de Sevilla", de Rossini, donde dieron una pequeña sorpresa al interrumpir por un momento su interpretación y cantar un "¡Fígaro!".',
      'El plato fuerte de la primera mitad del concierto fue la "Introducción y Rondó Caprichoso", de Saint-Saëns, obra donde viajaron de la solemnidad a la agilidad para continuar con una dosis de virtuosismo, una recomposición del ucraniano Yuri Povolotsky del célebre "Czardas", de Monti.',
      'En la segunda mitad del concierto rindieron homenaje a su legado judío con la "Obertura sobre temas hebreos", de Prokofiev, que dio paso a otra cátedra de destreza técnica con tres caprichos de Paganini, de los cuales el ultimo, el No. 24, sonó revestido en un arreglo con una exploración de armonía y timbre.',
      'Una probada de un lenguaje más moderno la presentaron con la Sonata para dos clarinetes, de Poulenc, para finalizar con las piezas "Pictures of a forgotten town" y "Jewish soul fantasy", también de Povolotsky, que brillaron por su original sonido, situado entre el jazz experimental y el klezmer.',
      'A la esencia de ese último estilo regresaron los gemelos al escenario, luego del aplauso cálido del público, para regalar un encore lleno de pasión judía.',
    ],
    title: 'Luis López, "El Norte"',
    subtitle: {
      url: 'https://www.elnorte.com/aplicaciones/articulo/default.aspx?id=1267067',
      text: 'Reference',
    },
    date: new Date(2017, Months.NOV, 30),
    image: '/image/reviews/mexico.jpg',
  }
];

const images = [
  'un_festival_de_haute_voltige_2019_10_06',
  'werner-richard-saal_review_sep_2019',
  'die_doppelte',
  'zwischen_tradition',
  'kreuth_2015',
  'ehemalige',
  'wunderkinder',
  '2020_kompakte_sternstunde',
  '2020_mit_frischem_blech',
].map(item => {
  return {
    original: `/image/reviews/${item}.jpg`,
    thumbnail: `/image/reviews/${item}_.jpg`
  }
});

const reviewsInGerman = [
  {
    url: 'https://www.donaukurier.de/nachrichten/kultur/Zwei-Kuenstler-ein-Gedanke;art598,4442757',
    text: 'Zwei Künstler, ein Gedanke (January 2020 - www.donaukurier.de)',
  },
  {
    url: 'http://www.wn.de/Muensterland/Kreis-Steinfurt/Steinfurt/1683047-Holzblaeser-Festival-in-der-Bagno-Konzertgalerie-Sommerlicher-Wind-im-Bagno',
    text: 'Holzbläser-Festival in der Bagno-Konzertgalerie',
  },
  {
    url: '/image/reviews/ingolstadt_concert_review_2014.pdf',
    text: 'Ingolstadt Concert Review (February 2014 - www.donaukurier.de) - PDF',
  },
  {
    url: 'http://www.volksfreund.de/nachrichten/region/kultur/Kultur-Melancholie-und-Lebensfreude;art764,4215279',
    text: 'Melancholie und Lebensfreude',
  },
  {
    url: 'http://www.blick-aktuell.de/Nachrichten/Brueder-entlockenKlarinetten-virtuose-Klaenge-138879.html',
    text: 'Brüder entlocken Klarinetten virtuose Klänge',
  },
  {
    url: '/image/reviews/ingolstadt_concert_review_2016.pdf',
    text: 'Ingolstadt Concert Review (February 2016 - www.donaukurier.de) - PDF',
  },
  {
    url: 'http://wn.de/Muensterland/Kreis-Steinfurt/Tecklenburg/3415416-Summerwinds-Festival-Musikalische-Reise-von-Klassik-bis-Jazz',
    text: 'Musikalische Reise von Klassik bis Jazz',
  },
  {
    url: 'https://www.morgenpost.de/kultur/article216227441/Die-Musik-Zwillinge-von-der-Hochschule-Hanns-Eisler.html',
    text: 'Die Musik-Zwillinge von der Hochschule "Hanns Eisler"'
  },
];

class ReviewsPage extends React.Component {
  generateContent(item) {
    switch (item.type) {
      case ReviewType.TESTIMONIAL:
        return <Testimonial {...item}/>;
      case ReviewType.TEXT_ONLY:
      case ReviewType.TEXT_AND_IMAGE:
        const {date, hideDays, image} = item;
        const dateString = `${MonthNames[date.getMonth()]} ${hideDays ? '' : date.getDate() + ', '}${date.getFullYear()}`;
        const divStyle = item.type === ReviewType.TEXT_ONLY ? {maxWidth: '556px'} : {width: '90%', marginLeft: '5%', marginRight: '5%'};
        return <div style={divStyle}>
          {
            item.text.map((text, index) => (
              <React.Fragment key={index}>
                {
                  item.type === ReviewType.TEXT_AND_IMAGE &&
                  <React.Fragment>
                  { index === 0 && <img style={{
                      width: '100%',
                      height: 'auto',
                      maxWidth: '407px',
                      float: 'right',
                      marginLeft: '10px',
                      marginBottom: '10px'
                    }} alt={dateString} src={image}/>
                  }
                  </React.Fragment>
                }
              <i style={{fontSize: '14px'}}>{text}</i>
              <br/>
            </React.Fragment>
            ))
          }
          <p style={{textAlign: 'right', color: 'rgba(255, 255, 255, 0.8)', marginBottom: '0px'}}>{item.title}
            {item.subtitle != null ? '. ' : (item.secondLineTitle ? '. ' : ', ')}
            {item.subtitle != null && <a href={item.subtitle.url} target="_blank" rel="noopener noreferrer" style={{
              color: 'rgba(255, 255, 255, 0.8)',
              textDecoration: 'underline'
            }}>{item.subtitle.text}</a>}
            {item.subtitle != null && ', '}
            {item.secondLineTitle != null &&
            <React.Fragment>
              <br/>{item.secondLineTitle}.&nbsp;
            </React.Fragment>
            }
            {dateString}.
          </p>
        </div>;
      default:
        return <div/>;
    }
  }

  render() {
    return (
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <ImageGallery items={images} additionalClass="reviews-gallery" thumbnailPosition="left"/>
        <p style={{marginTop: '30px'}}>
          <b>Additional reviews (in German):</b><br/>
          {
            reviewsInGerman.reverse().map(item => (
              <React.Fragment key={item.url}>
                <a href={item.url} target="_blank" rel="noopener noreferrer" style={{
                  color: 'rgba(255, 255, 255, 0.8)',
                  textDecoration: 'underline'
                }}>{item.text}</a>
                <br/>
              </React.Fragment>
            ))
          }
        </p>
        <ul>
          {
            reviews.map((item, index) =>
              (<li key={index} className={index % 2 === 0 ? 'right-review' : 'left-review'} style={item.type === ReviewType.TEXT_AND_IMAGE ? {marginLeft: '5%', marginRight: '5%'} : {}}>
                {this.generateContent(item)}
              </li>))
          }
        </ul>
      </div>
    );
  }
}

export default ReviewsPage;