import React from 'react';

import '../style/Testimonial.css';
import {MonthNames} from '../utils/Const';

class Testimonial extends React.Component {
  render() {
    const {text, title, date, image, hideDays, subtitle, mainTextStyle} = this.props;

    let secondLine;
    if (subtitle != null) {
      secondLine = subtitle;
    } else {
      secondLine = `${MonthNames[date.getMonth()]} ${hideDays ? '' : date.getDate() + ', '}${date.getFullYear()}`;
    }

    const messageStyle = mainTextStyle != null ? mainTextStyle : {};

    return <div className="review-container">
      <div className="message" style={messageStyle}>
        {text}
      </div>
      <div className="icon"
           style={{backgroundImage: `url('${image}')`}}>
      </div>
      <div className="footer">
        <div className="col-large">
          <p>{title}</p>
          <p>{secondLine}</p>
        </div>
        <div className="symbol">&rdquo;</div>
      </div>
    </div>
  }
}

Testimonial.defaultProps = {
  hideDays: false,
};

export default Testimonial;