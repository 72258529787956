import React from 'react';

class ContactPage extends React.Component {
  render() {
    return (
      <div>Under Construction</div>
    );
  }
}

export default ContactPage;


// Komarova Artists Management
// Tatjana Komarova
// World Management
// + 49 (0)30 88 91 65 35
//
// + 49 (0)172 9 34 79 76
//
// tk@komarova-artists.com
//
// www.komarova-artists.com
//
// facebook.com/komarovaartistsmanagement

// MG ArtPro Management
// Michael Gurfinkel
// Israel, Russia, Mexico, South America,
//   Countries of the post-Soviet space
// + 972 (0)52 5702531
//
// madgur2@gmail.com